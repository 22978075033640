import React, { useState } from 'react';
import { 
  RouteComponentProps,
  withRouter 
} from 'react-router-dom';
import { 
  IonLoading, 
  IonPage, 
  IonContent, 
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/react';
import { paperPlane } from 'ionicons/icons';
import api from '../api'
import './Result.css';

export const Result: React.FC<RouteComponentProps> = ({ history }) => {
  const [mode, setMode] = useState('loading');

  let secret = '';

  const payload = localStorage.getItem("payload") as string;
  const photos = JSON.parse(localStorage.getItem("photos") as string) as string[];

  if (payload) {
    const parsedPayload = JSON.parse(payload);
    debugger;

    if (parsedPayload) {
      secret = parsedPayload.secret;
    }
  }

  const sendPayload = () => {
    setTimeout(() => {
      if (payload == null) {
        console.error("no payload - no party");
        history.goBack();
      } else {
        console.log("photos : " + photos.length);
        Promise.all(
          photos.map(photoData => {
          const photoId = api.uploadPhoto(photoData, secret).then((photoId) => {
            return photoId;
          }).catch(e => console.error(e));
            console.log("photo id : " + photoId);
            return photoId;
        })).then(photoIds => {
          console.dir(photoIds);
          console.log("photo ids : " + photoIds);

          const data = JSON.parse(payload);
          if (photoIds.length > 0) {
            data["photos"] = photoIds;
          }

          api.sendFeedback(data)
            .then(result => {
              console.debug("send success : " + result);
              if (result) {
                localStorage.removeItem("payload");
                history.goBack()
              } else {
                setMode("error");
              }
            })
            .catch(error => {
              console.error("failure reason : " + error);
              setMode("error")
            });
        })
        .catch(e => console.error(e));
      }
    }, 500);
  };

  const content = () => {
    switch (mode) {
      case "loading": 
        return (
          <IonLoading
            isOpen={true}
            spinner="dots"
            keyboardClose={false}
            onDidPresent={sendPayload}
          />
        );
      case "error":
        return (
          <IonFab vertical="center" horizontal="center" slot="fixed">
          <IonFabButton onClick={e => setMode("loading")}>
            <IonIcon icon={paperPlane} />
          </IonFabButton>
          </IonFab>
        );
    }
  };

  return (
    <IonPage>
      <IonContent>
        {content()}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Result);