import axios from 'axios';
import defaultConfig from './config.json'

interface Vendor {
    id: string,
    bannerUrl: string
}

interface Camera {
    quality: number
}

interface FormInput {
    id: string,
    type: string,
    isRequired?: boolean,
    maxLength?: number,
    options?: SelectOption[],
    radios?: Radio[],
    value?: string,
    displayValue?: string
}

interface Radio {
    value: string
}

interface Checkbox {
    value: string,
    checked?: boolean
}

interface SelectOption {
    value: string,
    displayValue: string
}

interface FormBanner {
    url: string,
    visible: boolean
}

interface Form {
    id: string,
    icon: string,
    enabled: boolean,
    formBanner: FormBanner,
    inputs: FormInput[]
}

interface Config {
    vendor: Vendor,
    camera: Camera,
    forms: Form[]
}

interface PhotoUploadSuccess {
    result: string,
    photoId: string
}

interface PhotoUploadFailure {
    result: string,
    reason: string
}

interface SendFeedbackFailure {
    result: string,
    reason: string
}

class API {

    config: Config;

    constructor() {
        this.config = defaultConfig;

        axios.get("/config.json")
            .then(response => {
                this.config = response.data as Config;
                console.info("remote config loaded");
            })
            .catch(error => {
                console.info("remote config loading failure : " + error);
            });
    }

    uploadPhoto(base64data: string, secretKey: string) {
        console.debug("upload photo : " + base64data.length);
        return new Promise((resolve, reject) => {
            axios.post("https://uni.fromme.cc:7877/api/upload", {photo: base64data, secret: secretKey})
            .then(response => {
                console.debug(response);
                if (response.status === 200) {
                    const success = response.data as PhotoUploadSuccess;
                    resolve(success.photoId);
                } else {
                    const failure = response.data as PhotoUploadFailure;
                    reject(failure.reason);
                }
            })
            .catch(error => {
                console.error(error);
                reject(error);
            });
        });
    }

    sendFeedback(data: string) {
        console.debug("send feedback : " + data);
        return new Promise((resolve, reject) => {
            axios.post("https://uni.fromme.cc:7877/api/feedback", data)
            .then(response => {
                console.debug(response);
                if (response.status === 200) {
                    resolve(true);
                } else {
                    const failure = response.data as SendFeedbackFailure;
                    reject(failure.reason);
                }
            })
            .catch(error => {
                console.debug(error);
                reject(error);
            });
        });
    }
}

const api = new API();

export default api;