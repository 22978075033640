import axios from 'axios';
import defaultConfig from './defConfig.json'

interface Secret {
    type: string,
    secret: string
}

interface Config {
    secrets: Secret[]
}

class LocalConfig {

    config: Config;

    constructor() {
        this.config = defaultConfig;

        /*axios.get("/configLocal.json")
            .then(response => {
                this.config = response.data as Config;
                console.info("remote config loaded");
            })
            .catch(error => {
                console.info("remote config loading failure : " + error);
            });*/
    }

}

const localConfig = new LocalConfig();

export default localConfig;