import React from 'react';
import { 
  IonContent,
  IonHeader, 
  IonPage,
  IonFooter,
  IonToolbar, 
  IonCard, 
  IonImg, 
  IonCardHeader,
  IonItem,
  IonTitle, 
  IonIcon, 
  IonText,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import api from '../api'
import './Home.css';

const Home: React.FC = () => {

  const { t } = useTranslation();

  const content = api.config.forms.map((formItem) => {
    return (
      <IonCol size-xs="12" size-sm="6">
        <IonCard key={formItem.id} routerLink={'/form/' + formItem.id} disabled={!formItem.enabled}>
          <IonCardHeader>
              <IonItem lines="none">
                <IonIcon size="big" icon={'/icons/' + formItem.icon + '.svg'} slot="start" />
                <IonText>{t('forms.' + formItem.id + '.title')}</IonText>
              </IonItem>
          </IonCardHeader>
        </IonCard>
      </IonCol>
    );
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar >
          <IonImg className="vendor-banner" src={api.config.vendor.bannerUrl} />
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding main-page-content">
        <h4 className="vendor-description">{t('vendor_description.' + api.config.vendor.id)}</h4>
        <IonGrid>
          <IonRow>
            {content}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
