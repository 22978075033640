import React, {Suspense} from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonLoading, IonPage, IonContent } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Home from './pages/Home';
import Form from './pages/Form';
import Result from './pages/Result';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import api from './api'

console.log("VENDOR ID : " + api.config.vendor.id);

const Loader = () => (
  <IonPage>
    <IonContent>
      <IonLoading
        isOpen={true}
        spinner="dots"
        keyboardClose={false}
      />
    </IonContent>
  </IonPage>
);

const App: React.FC = () => (
  <Suspense fallback={<Loader />}>
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/home" component={Home} exact={true} />
        <Route exact path="/" render={() => <Redirect to="/home" />} />
        <Route path="/form/:id" component={Form} />
        <Route path="/result" component={Result} exact={true} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  </Suspense>
);

export default App;
