import React, { useState, FormEvent } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  IonList,
  IonInput,
  IonDatetime,
  IonTextarea,
  IonButtons,
  IonBackButton,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  IonFab,
  IonFabButton,
  IonItem,
  IonIcon,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonThumbnail,
  IonItemDivider,
  IonImg,
  IonToast,
} from "@ionic/react";
import { camera, paperPlane } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import { isNullOrUndefined } from "util";
import api from "../api";
import localConfig from "../localCfgs";
import "./Form.css";

interface FormPageProps extends RouteComponentProps<{ id: string }> {}

const Form: React.FC<FormPageProps> = ({ match, history }) => {
  const formId = match.params.id;
  const formItem = api.config.forms.find((x) => x.id === formId);
  const [data, setData] = useState({});
  const [photos, setPhotos] = useState([] as string[]);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const header = (formId: string) => {
    return (
      <IonHeader>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <IonBackButton text="" />
          </IonButtons>
          <IonTitle>{t("forms." + formId + ".title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
    );
  };

  const SelectItem = (inputId: string, isRequired: boolean, options: any[], selectedValue: string) => {
  debugger;
    return (
      <IonSelect value={selectedValue} placeholder={t("forms." + formId + "." + inputId + ".placeholder")}>
        {options.map((option) => (
          <IonSelectOption value={option.value}>{t("forms." + formId + "." + inputId + "." + option.displayValue, {defaultValue: option.displayValue})}</IonSelectOption>
        ))}
      </IonSelect>
    );
  };

  const RadioGroup = (inputId: string, radios: any[]) => {
    return (
      <IonRadioGroup>
        {radios.map((radio) => (
          <IonItem>
            <IonRadio value={radio.value} />{t("forms." + formId + "." + inputId + "." + radio.displayValue, {defaultValue: radio.displayValue})}
          </IonItem>
        ))}
      </IonRadioGroup>
    );
  };

  const Checkbox = (inputId: string, value: string, displayValue: string) => {
    return (
      <IonItem>
        <IonLabel>{t("forms." + formId + "." + inputId + "." + displayValue, {defaultValue: displayValue})}</IonLabel>
        <IonCheckbox slot="end" value={value} />
      </IonItem>
    );
  };

  const FormItem = (inputId: string, inputType: string, maxLength: number, isRequired: boolean) => {
    switch (inputType) {
      case "text":
      case "email":
      case "tel":
      case "number":
        return (
          <IonInput
            required={isRequired}
            placeholder={t("forms." + formId + "." + inputId + ".placeholder")}
            maxlength={maxLength}
            type={inputType}
            onIonChange={(e) => setData({ ...data, [inputId]: e.detail.value })}
          ></IonInput>
        );
      case "datetime":
        return (
          <IonDatetime
            placeholder={t("forms." + formId + "." + inputId + ".placeholder")}
            onIonChange={(e) => setData({ ...data, [inputId]: e.detail.value })}
          ></IonDatetime>
        );
      case "textarea":
        return (
          <IonTextarea
            autoGrow={true}
            rows={3}
            wrap="soft"
            required={isRequired}
            placeholder={t("forms." + formId + "." + inputId + ".placeholder")}
            maxlength={maxLength}
            onIonChange={(e) => setData({ ...data, [inputId]: e.detail.value })}
          ></IonTextarea>
        );
      case "photos":
        return (
          <IonGrid fixed={true}>
            <IonRow className="ion-justify-content-center ion-align-items-center">
              <IonButton className="square" onClick={takePhoto} shape="round" color="light">
                <IonIcon icon={camera} slot="icon-only" />
              </IonButton>
              {photos.map((photo, index) => (
                <IonCol size="3" size-lg>
                  <IonThumbnail className="square" onClick={() => removePhoto(index)}>
                    <IonImg src={photo} />
                  </IonThumbnail>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        );
      default:
        console.error("unsupported input type : " + inputType);
        break;
    }
  };

  const content = (formId: string) => {
    let showBanner = false;

    if (formItem) {
      if (formItem.formBanner.url.length > 0 && formItem.formBanner.visible) {
        showBanner = true;
      }
    }
    
    return (
      <IonContent className="ion-padding">
        <IonImg src={formItem?.formBanner.url} hidden={!showBanner} />
        <p className="form-description">{t("forms." + formId + ".description")}</p>
        <p><strong>{t("forms." + formId + ".feedback_form_title")}</strong></p>
        <form onSubmit={onSubmit}>
          <IonList lines="none">
            {formItem?.inputs.map((formInput) => {
              switch (formInput.type) {
                case "photos":
                  return (
                    <IonItem key={formInput.id}>
                      {FormItem(formInput.id, formInput.type, formInput.maxLength || 255, formInput.isRequired || false)}
                    </IonItem>
                  );
                case "radio":
                  return (
                    <IonItem key={formInput.id}>
                      {RadioGroup(formInput.id, formInput.radios || [])}
                    </IonItem>);
                case "checkbox":
                  return (
                    <IonItem key={formInput.id}>{Checkbox(formInput.id, formInput.value || '', formInput.displayValue || '')}</IonItem>
                  );
                case "select":
                  return (
                    <IonItem key={formInput.id}>
                      <IonLabel position="stacked" color="primary">
                        {t("forms." + formId + "." + formInput.id + ".label")}
                      </IonLabel>
                      {SelectItem(formInput.id, formInput.isRequired || false, formInput.options || [], formInput.value || '')}
                    </IonItem>
                  );
                default:
                  return (
                    <IonItem key={formInput.id}>
                      <IonLabel position="stacked" color="primary">
                        {t("forms." + formId + "." + formInput.id + ".label")}
                      </IonLabel>
                      {FormItem(formInput.id, formInput.type, formInput.maxLength || 255, formInput.isRequired || false)}
                    </IonItem>
                  );
              }
            })}
          </IonList>
          <IonButton type="submit" expand="full" strong={true}>
            {t("submit_button_text")}
          </IonButton>
        </form>

        {toast()}
      </IonContent>
    );
  };

  const toast = () => {
    return (
      <IonToast
        isOpen={error.length > 0}
        onDidDismiss={() => setError("")}
        color="warning"
        header={t("validation_error_header")}
        message={error}
        duration={2000}
      ></IonToast>
    );
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    console.log("submit");
    console.dir(data);

    let secret = "";

    if (localConfig.config.secrets) {
      let secretData = localConfig.config.secrets.find((x) => x.type === "uni_secret");

      if (secretData) {
        secret = secretData.secret;
      }
    }

    const payload = { formId: formId, secret: secret, data: data };
    localStorage.setItem("payload", JSON.stringify(payload));
    localStorage.setItem("photos", JSON.stringify(photos));
    history.replace("/result");
  };

  const removePhoto = (index: number) => {
    console.log("remove photo # " + index);
    setPhotos([...photos.slice(0, index), ...photos.slice(index + 1)]);
  };

  const takePhoto = () => {
    console.log("take photo");
    Plugins.Camera.getPhoto({
      quality: api.config?.camera.quality,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Prompt,
    })
      .then((photo) => {
        console.dir(photo);
        console.log("photo size : " + photo.dataUrl?.length);
        if (photo.dataUrl != null) {
          setPhotos([...photos, photo.dataUrl]);
        }
      })
      .catch((e) => console.error(e));
  };

  return (
    <IonPage>
      {header(formId)}
      {content(formId)}
    </IonPage>
  );
};

export default withRouter(Form);
